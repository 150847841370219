import Modal from 'bootstrap/js/src/Modal';

(function( $ ) {

  if($('#map').length) {

    let map = false;
    let geojson = false;

    let currentGroupTypeFilters = [];
    let currentProgramFilters = [];
    let currentProgramSizeFilters = [];
    let currentNewSpeakerFilters = [];
    let currentlyHiddenFeatureIDs = [];

    setSidebarEventListeners();
    setMobileEvents();
    createMap();

    // Map functions
    function createMap() {
      mapboxgl.accessToken = 'pk.eyJ1IjoibmlsbGEtbWFwYm94IiwiYSI6ImNsY2pjaXBlcjJzdzYzcXA0ZjB1dmhweG4ifQ.HMEHTPDx5f3LCDcdEw2cWA';
      map = new mapboxgl.Map({
        container: 'map', // container ID
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        style: 'mapbox://styles/nilla-mapbox/clcjk88s3009c15qsmdds4vrh', // style URL
        center: [-96.011495438, 49.98608362], // starting position [lng, lat]
        zoom: 3, // starting zoom
        projection : 'mercator'
      });
      map.on('load', () => {
        addPins()
      });
    }

    async function addPins() {
      let mapPins = ['Academic', 'Community', 'Organization'];

      mapPins.forEach(pin => {
        map.loadImage(`${window.wpApiSettings.theme}/assets/map/${pin.toLowerCase()}-pin.png`, function(error, image) {
          map.addImage(pin, image)
        });
      })

      geojson = await fetchLocations()

      console.log(geojson)

      geojson.features.forEach((feature, index) => {
        feature.properties.id = index;
      })

      map.fitBounds(turf.bbox(geojson), { padding : 20 });

      map.addSource('locations', {
        type : "geojson",
        data : geojson
      });

      map.addLayer({
        id : 'locations',
        source : 'locations',
        type : 'symbol',
        layout : {
          'icon-image' : ['get', 'type'],
          'icon-size' : 0.4,
          'icon-anchor' : 'bottom',
          'icon-allow-overlap' : true
        }
      })

      map.on('click', 'locations', (e) => {
        let features = map.queryRenderedFeatures(e.point, { layers : ['locations']} );
        if(features.length > 0) {
          selectPin(features[0]);
          // $('.bottom-section').fadeOut();
          $(`#feature-${features[0].properties.id} .bottom-section`).fadeIn();
          $(`#feature-${features[0].properties.id}`)[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
      })

      populateFilters();
      addCards();
    }

    function selectPin(feature) {
      map.setLayoutProperty('locations', 'icon-size', [
        "case",
        ['in', ['get', 'id'], ["literal", currentlyHiddenFeatureIDs]],
        0,
        ['==', feature.properties.id, ['get', 'id']],
        0.6,
        0.4
      ])
    }

    async function fetchLocations() {
      return await fetch('https://webapp.library.uvic.ca/nilla_app/api/api.php?request=geojson').then(resp => resp.json())
    }

    // Filter functions
    function clearFilters() {
      let { groupTypeOptions, programOptions, programSizeOptions, newSpeakersOptions } = getAllOptions();

      currentGroupTypeFilters = groupTypeOptions;
      currentProgramFilters = programOptions;
      currentProgramSizeFilters = programSizeOptions;
      currentNewSpeakerFilters = newSpeakersOptions;

      $('.group-type input').each(function() { $(this).prop('checked', false) })
      $('.program input').each(function() { $(this).prop('checked', false) })
      $('.program-size input').each(function() { $(this).prop('checked', false) })
      $('.new-speakers input').each(function() { $(this).prop('checked', false) })

      hidePoints(filterPointsAndResults());
    }

    function getAllOptions() {
      let groupTypeOptions = [];
      let programOptions = [];
      let programSizeOptions = [];
      let newSpeakersOptions = [];
      geojson.features.forEach((feature, index) => {
        // Populating filter dropdowns
        if(groupTypeOptions.indexOf(feature.properties.type) === -1 && feature.properties.type !== "") {
          groupTypeOptions.push(feature.properties.type);
        }
        let programs = feature.properties.Other_lang_Progs.split(',');
        programs.forEach(program => {
          let thisProgram = program.trim();
          if(programOptions.indexOf(thisProgram) === -1 && thisProgram !== "") {
            programOptions.push(thisProgram);
          }
        })
        if(programSizeOptions.indexOf(feature.properties.Community_size) === -1 && feature.properties.Community_size !== "") {
          programSizeOptions.push(feature.properties.Community_size);
        }
        if(newSpeakersOptions.indexOf(feature.properties.Created_new_speakers) === -1 && feature.properties.Created_new_speakers !== "") {
          newSpeakersOptions.push(feature.properties.Created_new_speakers);
        }
      });
      return {
        groupTypeOptions, programOptions, programSizeOptions, newSpeakersOptions
      }
    }

    function changeCounts() {
      let { groupTypeOptions, programOptions, programSizeOptions, newSpeakersOptions } = getAllOptions();
      let filterFeatureCount = false;
      let filterCount = false;
      if(currentGroupTypeFilters.length === groupTypeOptions.length && currentProgramFilters.length === programOptions.length && currentProgramSizeFilters.length === programSizeOptions.length && currentNewSpeakerFilters.length === newSpeakersOptions.length) {
        filterFeatureCount = "all";
        filterCount = "None";
      } else {
        filterCount = 0;
        if(currentGroupTypeFilters.length !== groupTypeOptions.length) {
          filterCount = filterCount + currentGroupTypeFilters.length;
        }
        if(currentProgramFilters.length !== programOptions.length) {
          filterCount = filterCount + currentProgramFilters.length;
        }
        if(currentProgramSizeFilters.length !== programSizeOptions.length) {
          filterCount = filterCount + currentProgramSizeFilters.length;
        }
        if(currentNewSpeakerFilters.length !== newSpeakersOptions.length) {
          filterCount = filterCount + currentNewSpeakerFilters.length;
        }
        let hiddenFeatures = filterPointsAndResults();
        filterFeatureCount = geojson.features.length - hiddenFeatures.length;
      }
      $('.filter-feature-count').html(filterFeatureCount);
      $('.filter-count').html(filterCount);
    }

    function populateFilters() {

      let { groupTypeOptions, programOptions, programSizeOptions, newSpeakersOptions } = getAllOptions();

      currentGroupTypeFilters = groupTypeOptions;
      currentProgramFilters = programOptions;
      currentProgramSizeFilters = programSizeOptions;
      currentNewSpeakerFilters = newSpeakersOptions;

      groupTypeOptions.forEach(option => {
        $('.group-type').append(`<div class="nilla-checkbox body-sm"><input type="checkbox" value=${option}>${option}</option></div>`);
      })

      programOptions.forEach(option => {
        $('.program').append(`<div class="nilla-checkbox body-sm"><input type="checkbox" value=${option}>${option}</option></div>`);
      })

      programSizeOptions.forEach(option => {
        $('.program-size').append(`<div class="nilla-checkbox body-sm"><input type="checkbox" value=${option}>${option}</option></div>`);
      })

      newSpeakersOptions.forEach(option => {
        $('.new-speakers').append(`<div class="nilla-checkbox body-sm"><input type="checkbox" value=${option}>${option}</option></div>`);
      })

      $(document).on('change', '.group-type', function() {
        let currentVal = [];
        $('.group-type input').each(function() {
          if($(this).prop('checked')) {
            currentVal.push($(this).val())
          }
        })
        if(currentVal.length === 0) {
          currentGroupTypeFilters = groupTypeOptions
        } else {
          currentGroupTypeFilters = currentVal
        }
        hidePoints(filterPointsAndResults());
      })
      $(document).on('change', '.program', function() {
        let currentVal = [];
        $('.program input').each(function() {
          if($(this).prop('checked')) {
            currentVal.push($(this).val())
          }
        })
        if(currentVal.length === 0) {
          currentProgramFilters = programOptions
        } else {
          currentProgramFilters = currentVal
        }
        hidePoints(filterPointsAndResults());
      })
      $(document).on('change', '.program-size', function() {
        let currentVal = [];
        $('.program-size input').each(function() {
          if($(this).prop('checked')) {
            currentVal.push($(this).val())
          }
        })
        if(currentVal.length === 0) {
          currentProgramSizeFilters = programSizeOptions
        } else {
          currentProgramSizeFilters = currentVal
        }
        hidePoints(filterPointsAndResults());
      })
      $(document).on('change', '.new-speakers', function() {
        let currentVal = [];
        $('.new-speakers input').each(function() {
          if($(this).prop('checked')) {
            currentVal.push($(this).val())
          }
        })
        if(currentVal.length === 0) {
          currentNewSpeakerFilters = newSpeakersOptions
        } else {
          currentNewSpeakerFilters = currentVal
        }
        hidePoints(filterPointsAndResults());
      })
    }

    // Returning only if matches all results, and ones with blanks always show
    function filterPointsAndResults() {
      let featuresToHide = [];
      geojson.features.forEach(feature => {
        if(currentGroupTypeFilters.indexOf(feature.properties.type) === -1) {
          featuresToHide.push(feature);
          return;
        }
        if(currentProgramSizeFilters.indexOf(feature.properties.Community_size) === -1 && feature.properties.Community_size !== "") {
          featuresToHide.push(feature);
          return;
        }
        if(currentNewSpeakerFilters.indexOf(feature.properties.Created_new_speakers) === -1 && feature.properties.Created_new_speakers !== "") {
          featuresToHide.push(feature);
          return;
        }

        if(feature.properties.Other_lang_Progs === "") {
          return;
        } else {
          let programs = feature.properties.Other_lang_Progs.split(',');
          let foundProgram = false;
          programs.forEach(program => {
            let thisProgram = program.trim();
            if(currentProgramFilters.indexOf(program) > -1) {
              foundProgram = true;
            }
          });
          if(!foundProgram) {
            featuresToHide.push(feature)
            return;
          }
        }
      })

      currentlyHiddenFeatureIDs = featuresToHide.map(feature => feature.properties.id);
      return currentlyHiddenFeatureIDs;
    }

    function hidePoints(currentlyHiddenFeatureIDs) {
      map.setLayoutProperty('locations', 'icon-size', [
        "case",
        ['in', ['get', 'id'], ["literal", currentlyHiddenFeatureIDs]],
        0,
        0.4
      ])
      $('.single-map-card').each(function() {
        if($(this).attr('id') !== "map-card-template") {
          let thisID = parseInt($(this).attr('id').replace("feature-", ""));
          if(currentlyHiddenFeatureIDs.indexOf(thisID) > -1) {
            $(this).hide();
          } else {
            $(this).show();
          }
        }
      })
      changeCounts();
    }

    // Sidebar functions
    function addCards() {
      let colors = {
        "Academic" : "#186B39",
        "Community" : "#AF1B12",
        "Organization" : "#7D2D6B"
      }
      geojson.features.forEach((feature, index) => {
        let template = $('#map-card-template').clone();
        template.removeAttr('id');
        template.attr('id', `feature-${index}`);
        let replacedCharacters = feature.properties.name.replace('?', 'ʔ');
        template.find('h5').html(replacedCharacters);
        template.find('.card').css('border-left', `8px ${colors[feature.properties.type]} solid`);
        let existingTypeIcon = template.find('.metadata-type img').attr('src')
        let newTypeIcon = existingTypeIcon.replace('academic-icon', `${feature.properties.type.toLowerCase()}-icon`);
        template.find('.metadata-type img').attr('src', newTypeIcon)
        template.find('.metadata-type span.to-replace').html(feature.properties.type);
        template.find('.metadata-location span.to-replace').html(feature.properties.location);
        template.find('.metadata-language span.to-replace').html(feature.properties.language !== "" ? feature.properties.language : "Unspecified");
        if(feature.properties.How_r_new_speakers_created !== "") {
          if(feature.properties.Created_new_speakers === "Yes") {
            template.find('.metadata-new-speakers .alert-section').html(`<div class="alert alert-nilla body-reg-semibold"><img src="${window.wpApiSettings.theme}/assets/map/celebrate-icon.png" /> New speakers are being created!</div>`)
          }
          template.find('.metadata-new-speakers .content').append(feature.properties.How_r_new_speakers_created);
        } else {
          template.find('.metadata-new-speakers').hide();
        }
        if(feature.properties.Other_lang_Progs !== "") {
          let items = feature.properties.Other_lang_Progs.split(',');
          items.forEach(item => {
            let trimmedItem = item.trim();
            template.find('.metadata-other-contribs .content').append(`<li>${trimmedItem}</li>`);
          })
        } else {
          template.find('.metadata-other-contribs').hide();
        }
        if(feature.properties.Website !== "") {
          let items = feature.properties.Website.split(',');
          items.forEach(item => {
            let trimmedItem = item.trim();
            template.find('.metadata-links .content').html(`<li><a class="btn-reg-text text-blue-500" href="${trimmedItem}" target="_blank">${trimmedItem} <img src="${window.wpApiSettings.theme}/assets/map/icon-right.png" /></a></li>`);
          });
        } else {
          template.find('.metadata-links').hide();
        }
        template.show();
        $(".map-cards").append(template);
      });
    }

    function setMobileEvents() {
      let modalFilters = new Modal(document.getElementById('nilla-modal-atlas-filters'))
      $(document).on('click', '.mobile-filters-icon', function() {
        if(window.innerWidth < 1068) {
          modalFilters.show();
        }
      });
      let modalInfo = new Modal(document.getElementById('nilla-modal-atlas-info'))
      $(document).on('click', '.open-info', function() {
        if(window.innerWidth < 1068) {
          modalInfo.show();
        }
      });

      $(document).on('click', '.filter-toggle-mobile', function() {
        if($('.sidebar-area').hasClass('slid-up')) {
          $('.sidebar-area').removeClass('slid-up')
        } else {
          $('.sidebar-area').addClass('slid-up')
        }
      });
    }

    function setSidebarEventListeners() {
      $(document).on('click', '.map-header img', function() {
        if(window.innerWidth > 1068) {
          if($('.map-header .info').is(':visible')) {
            $('.map-header .info').fadeOut();
          } else {
            $('.map-header .info').fadeIn();
          }
        }
      });

      $(document).on('click', '.filter-summary', function() {
        if($('.filters').is(':visible')) {
          $('.filters').fadeOut();
          $(".caret-holder img").css('transform', 'rotate(0deg)');
        } else {
          $('.filters').fadeIn();
          $(".caret-holder img").css('transform', 'rotate(180deg)');
        }
      })

      $(document).on('click', '.clear-filters', function() {
        clearFilters();
      })

      // $(document).on('click', '.single-map-card', function() {
      //   let thisID = $(this).attr('id').replace('feature-', '');
      //   let thisFeature = geojson.features.find(feature => feature.properties.id === parseInt(thisID));
      //   selectPin(thisFeature);
      //   // $('.bottom-section').fadeOut();
      //   $(this).find('.bottom-section').fadeIn();
      // })

      $(document).on('click', '.open-close-card', function() {
        let parent = $(this).closest('.single-map-card')
        let thisID = $(parent).attr('id').replace('feature-', '');
        if($(this).hasClass('opened')) {
          $(parent).find('.bottom-section').fadeOut();
          $(this).removeClass('opened')
        } else {
          let thisFeature = geojson.features.find(feature => feature.properties.id === parseInt(thisID));
          selectPin(thisFeature);
          $(parent).find('.bottom-section').fadeIn();
          $(this).addClass('opened')
        }
      })
    }
  }

})(jQuery);
