import Modal from 'bootstrap/js/src/Modal';

(function( $ ) {

  let currentPage = false

  // Counts
  if($('.community-count').length) {
    fetch('https://webapp.library.uvic.ca/nilla_app/api/api.php?request=geojson').then(resp => resp.json()).then(response => {
      let communities = response.features.filter(feature => feature.properties.type === "Community");
      let orgs = response.features.filter(feature => feature.properties.type === "Organization");
      let academics = response.features.filter(feature => feature.properties.type === "Academic");
      $(".community-count").html(communities.length)
      $(".organization-count").html(orgs.length)
      $(".academic-count").html(academics.length)
    })
  }

  // Modal Management
  if($('body').hasClass("tax-discussion-category") || $('body').hasClass("tax-discussion-tag") || $('body').hasClass("single-discussions")) {
    currentPage = 'discussions';
  }
  if($('body').hasClass("tax-resource-category") || $('body').hasClass("tax-resource-tag") || $('body').hasClass("single-resources") || $('body').hasClass("post-type-archive-resources") ) {
    currentPage = 'resources';
  }
  if(currentPage) {
    preloadModalData();
    preloadCommentData();
    addModalEventListeners();
    addUploadEventListeners();
    addCommentEventListeners();
    addLikeEventListeners();
  } else {
    if($('#nilla-modal').length) {
      addModalEventListeners();
    }
  }

  // Customize slider
  if($('.carousel .carousel-item').length > 0) {
    let items = document.querySelectorAll('.carousel .carousel-item')
    		items.forEach((el) => {
    			const minPerSlide = 3
    			let next = el.nextElementSibling
    			for (var i=1; i<minPerSlide; i++) {
    				if (!next) {
                // wrap carousel by using first child
                next = items[0]
            }
            let cloneChild = next.cloneNode(true)
            el.appendChild(cloneChild.children[0])
            next = next.nextElementSibling
        }
    })
  }

  // Loading user information into modal if checked
  function preloadModalData() {
    let nillaUserInfo = window.localStorage.getItem('nilla_user');
    if(nillaUserInfo) {
      nillaUserInfo = JSON.parse(nillaUserInfo);
      if(nillaUserInfo.remember_me) {
        $('#personal-name').val(nillaUserInfo.name);
        $('#personal-email').val(nillaUserInfo.email);
        $('#personal-role').val(nillaUserInfo.role);
        $('#personal-org').val(nillaUserInfo.org);
        $('#show-me').prop('checked', nillaUserInfo.show_name);
        $('#email-me').prop('checked', nillaUserInfo.email_me);
        $('#remember-me').prop('checked', nillaUserInfo.remember_me);
      }
    }
  }
  function preloadCommentData() {
    let nillaUserInfo = window.localStorage.getItem('nilla_user');
    if(nillaUserInfo) {
      nillaUserInfo = JSON.parse(nillaUserInfo);
      if(nillaUserInfo.remember_me) {
        $('#personal-name-comment').val(nillaUserInfo.name);
        $('#personal-email-comment').val(nillaUserInfo.email);
        $('#personal-role-comment').val(nillaUserInfo.role);
        $('#personal-org-comment').val(nillaUserInfo.org);
        $('#show-me-comment').prop('checked', nillaUserInfo.show_name);
        $('#email-me-comment').prop('checked', nillaUserInfo.email_me);
        $('#remember-me-comment').prop('checked', nillaUserInfo.remember_me);
      }
    }
  }

  // Various listeners to make sure modal is working
  function addModalEventListeners() {
    let modalToOpen = new Modal(document.getElementById('nilla-modal'))
    $(document).on('click', '.open-modal', function() {
      modalToOpen.show();
    })
    $(document).on('click', '.input-row .pill-container .pill', function() {
      if($(this).hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }
    })
    $(document).on('click', '.video-add-row', function() {
      if($('.video-to-add').length < 5) {
        let videoToAdd = `<input class="video-to-add form-control" type="text" placeholder="https:// paste URL video here" />`;
        $('.video-inputs').append(videoToAdd);
      } else {
        $('.video-add-row').hide();
      }
    });
    $(document).on('click', '#save-new-post', async function() {
      let user_info = await logInUser();
      if(user_info) {
        if(user_info.remember_me) {
          window.localStorage.setItem('nilla_user', JSON.stringify(user_info));
        } else {
          window.localStorage.removeItem('nilla_user');
        }
        createPost(user_info.id);
      }
    })
  }

  // Handling uploads (done before the post is created)
  function addUploadEventListeners() {
    $(document).on('dragover', '.uploader-drag-and-drop', function(e) {
      e.preventDefault();
      $(this).addClass('active')
    })
    $(document).on('dragleave', '.uploader-drag-and-drop', function(e) {
      e.preventDefault();
      $(this).removeClass('active')
    })
    $(document).on('drop', '.uploader-drag-and-drop', function(e) {
      e.preventDefault();
      if(e.originalEvent && e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files[0]) {
        uploadFile(e.originalEvent.dataTransfer.files[0]);
      }
      $(this).removeClass('active')
    })
    $(document).on('change', '#new-post-upload', function() {
      const profilePicInput = document.getElementById("new-post-upload");
      if(profilePicInput.files && profilePicInput.files[0]) {
        uploadFile(profilePicInput.files[0])
      }
    })
    $(document).on('click', '#trigger-file-upload', function() {
      $('#new-post-upload').trigger('click');
    })
    $(document).on('click', '.uploaded-file-delete', function() {
      $(this).parent().remove();
    })
  }

  function addLikeEventListeners() {
    $(document).on('click', '.nilla-like-post', function() {
      let post_id = $(this).data('post');
      let liked_posts = localStorage.getItem('nilla_posts_liked') ? JSON.parse(localStorage.getItem('nilla_posts_liked')) : []
      let user_already_liked = liked_posts.indexOf(post_id) > -1;

      fetch(`${window.wpApiSettings.root}nilla/v1/like-post?post_id=${post_id}&user_already_liked=${user_already_liked}`).then(resp => resp.json()).then(response => {
        $('.nilla-like-post-count').html(response);
        let new_liked_posts = JSON.parse(JSON.stringify(liked_posts));
        if(user_already_liked) {
          new_liked_posts = liked_posts.filter(postID => postID !== post_id);
        } else {
          new_liked_posts.push(post_id);
        }
        localStorage.setItem('nilla_posts_liked', JSON.stringify(new_liked_posts));
      })
    });
  }

  function addCommentEventListeners() {
    $(document).on('click', '.toggle-child-comments', function() {
      let childComments = $(this).parents('.commenter').find('.commenter-child');
      if(childComments.length) {
        childComments.slideToggle();
      }
    });
    $(document).on('click', '.reply-comment', function() {
      let thisParentComment = $(this).data('parent-id');
      $(".parent-comment-id").data('parent-id', thisParentComment);
      console.log($(".parent-comment-id").data('parent-id'))
      $('.post-comment-form').show();
      $('.post-comment-form')[0].scrollIntoView();
    });
    $(document).on('click', '.open-comment-form', function() {
      $(".parent-comment-id").removeData('parent-id');
      $('.post-comment-form').show();
      $('.post-comment-form')[0].scrollIntoView();
    })
    $(document).on('click', '.cancel-new-comment', function() {
      $(".parent-comment-id").removeData('parent-id');
      $('.post-comment-form').hide();
    })
    $(document).on('click', '#save-new-comment', async function() {
      let user_info = await logInUser(true);
      if(user_info) {
        if(user_info.remember_me) {
          window.localStorage.setItem('nilla_user', JSON.stringify(user_info));
        } else {
          window.localStorage.removeItem('nilla_user');
        }
        createComment(user_info.id);
      }
    })
  }

  function createComment(userID) {
    let article_id = $('#main').find('article').attr('id');
    let post_id = article_id.replace('post-', '')
    let commentParams = {
      user_id : userID,
      post_id : post_id,
      content : $('#new-comment').val()
    }
    if($(".parent-comment-id").data('parent-id')) {
      commentParams["parent_comment_id"] = $(".parent-comment-id").data('parent-id');
    }
    fetch(`${window.wpApiSettings.root}nilla/v1/create-comment`, {
      method : "POST",
      headers : { "Content-Type" : "application/json" },
      body : JSON.stringify(commentParams)
    }).then(resp => resp.json()).then(response => {
      window.location.reload();
    })
  }

  function createPost(userID) {
    let postParams = {
      user_id : userID,
      post_type : currentPage,
      post_title : $('#new-post-title').val(),
      post_cat : $('#new-post-category').val(),
      post_tags : getPostTags(),
      content : tinymce.get("new-post-content").getContent(),
      attachments : getAttachmentIDs(),
      videos : getVideoURLs()
    }
    if(currentPage === 'resources') {
      postParams.summary = $('#new-post-summary').val();
    }
    if(postParams.post_title === "" || postParams.post_cat === "" || postParams.content === "") {
      window.alert("You must enter a title, a category, and some content in order to submit a post.");
    } else {
      fetch(`${window.wpApiSettings.root}nilla/v1/create-post`, {
        method : "POST",
        headers : { "Content-Type" : "application/json" },
        body : JSON.stringify(postParams)
      }).then(resp => resp.json()).then(response => {
        console.log(response)
        if(response.post_author) {
          $('.form-submit').hide();
          $(".modal-header").hide();
          $('.thank-you').show();
        } else {
          window.alert("There was an error submitting your post. Please contact NILLA for help!")
        }
      })
    }
  }

  async function logInUser(isComment) {
    if($(`#personal-name${isComment ? '-comment' : ''}`).val() === "" || $(`#personal-email${isComment ? '-comment' : ''}`).val() === "") {
      window.alert("You must include a name and email in order to create a post.");
      return false;
    } else {
      let user_info = await fetch(`${window.wpApiSettings.root}nilla/v1/login-user`, {
        method : "POST",
        headers : { "Content-Type" : "application/json" },
        body : JSON.stringify({
          name : $(`#personal-name${isComment ? '-comment' : ''}`).val(),
          email : $(`#personal-email${isComment ? '-comment' : ''}`).val(),
          nilla_role : $('#personal-role').val(),
          nilla_org : $('#personal-org').val(),
          nilla_show_name : $('#show-me').is(':checked'),
          nilla_email_me : $('#email-me').is(':checked'),
          nilla_remember_me : $('#remember-me').is(':checked'),
        })
      }).then(resp => resp.json());
      return user_info;
    }
  }

  function uploadFile(file) {
    if($('.uploaded-file').length < 6) { // One extra because of the empty template
      const formData = new FormData();
      formData.append("file", file);

      fetch(`${window.wpApiSettings.root}nilla/v1/upload-media`, {
        method : "POST",
        body : formData
      }).then(resp => resp.json()).then(response => {
        let cloneFileSection = $('#uploaded-file-template').clone()
        cloneFileSection.removeAttr('id');
        cloneFileSection.addClass('actual-file');
        let fileIcon = response.post_mime_type.indexOf('image') > -1 ? 'image' : 'doc';
        let currentSrc = cloneFileSection.find('.uploaded-file-image img').attr('src')

        cloneFileSection.attr('attachment-id', response.ID);
        cloneFileSection.find('.uploaded-file-image img').attr('src', currentSrc.replace('image-icon', `${fileIcon}-icon`));
        cloneFileSection.find('.uploaded-file-title').html(response.post_name);
        cloneFileSection.find('.uploaded-file-size').html(`${Math.floor(response.filesize / 10000) / 100}MB`);
        $('.uploader-files').prepend(cloneFileSection)
        cloneFileSection.show();
      });
    }
  }

  // Helpful gather functions
  function getPostTags() {
    let postTags = [];
    $('.pill-container .pill').each(function() {
      if($(this).hasClass('active')) {
        postTags.push($(this).attr('tag-id'))
      }
    })
    return postTags;
  }

  function getAttachmentIDs() {
    let attachmentIDs = [];
    $('.uploaded-file.actual-file').each(function() {
      attachmentIDs.push($(this).attr('attachment-id'));
    })
    return attachmentIDs;
  }

  function getVideoURLs() {
    let videoURLs = [];
    $('.video-to-add').each(function() {
      if($(this).val() !== "") {
        videoURLs.push($(this).val())
      }
    })
    return videoURLs;
  }

})( jQuery );
